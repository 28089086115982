<template>
  <div id="h5Iframe">
    <iframe :src="url"></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: ''
    }
  },
  methods: {},
  created() {
    console.log(this.$route.query.url,'url')
    this.url=this.$route.query.url
  }
}
</script>
<style lang="less" scoped>
#h5Iframe {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
